<template>
  <div>
    <editor-menu-bar
      :editor="editor"
      v-slot="{ commands, isActive, getMarkAttrs }"
      style="padding: 0 10px 10px 0; margin-top: 8px"
    >
      <div>
        <div class="ui icon buttons">
          <button
            class="ui button"
            :class="{ 'is-active': isActive.bold() }"
            @click.prevent="commands.bold"
          >
            <i class="bold icon"></i>
          </button>
          <button
            class="ui button"
            :class="{ 'is-active': isActive.italic() }"
            @click.prevent="commands.italic"
          >
            <i class="italic icon"></i>
          </button>
          <button
            class="ui button"
            :class="{ 'is-active': isActive.underline() }"
            @click.prevent="commands.underline"
          >
            <i class="underline icon"></i>
          </button>
        </div>
        <div class="ui icon buttons" style="margin-left: 10px">
          <button
            class="ui button"
            :class="{ 'is-active': isActive.heading({ level: 1 }) }"
            @click.prevent="commands.heading({ level: 1 })"
          >
            H1
          </button>
          <button
            class="ui button"
            :class="{ 'is-active': isActive.heading({ level: 2 }) }"
            @click.prevent="commands.heading({ level: 2 })"
          >
            H2
          </button>
          <button
            class="ui button"
            :class="{ 'is-active': isActive.heading({ level: 3 }) }"
            @click.prevent="commands.heading({ level: 3 })"
          >
            H3
          </button>
          <button
            class="ui button"
            :class="{ 'is-active': isActive.paragraph() }"
            @click.prevent="commands.paragraph"
          >
            <i class="paragraph icon"></i>
          </button>
        </div>
        <div class="ui icon buttons" style="margin-left: 10px">
          <button
            class="ui button"
            :class="{ 'is-active': isActive.bullet_list() }"
            @click.prevent="commands.bullet_list"
          >
            <i class="list ul icon"></i>
          </button>

          <button
            class="ui button"
            :class="{ 'is-active': isActive.ordered_list() }"
            @click.prevent="commands.ordered_list"
          >
            <i class="list ol icon"></i>
          </button>
        </div>
        <div class="ui icon buttons" style="margin-left: 10px">
          <button
            class="ui button"
            :class="{ 'is-active': isActive.blockquote() }"
            @click.prevent="commands.blockquote"
          >
            <i class="quote right icon"></i>
          </button>
        </div>
        <div class="ui icon buttons" style="margin-left: 10px">
          <button
            class="ui button"
            :class="{ 'is-active': isActive.link() }"
            @click.prevent="showLinkMenu(getMarkAttrs('link'))"
          >
            <i class="linkify icon"></i>
          </button>
          <button
            class="ui button"
            @click.prevent="unsetLinkUrl(commands.link, getMarkAttrs('link'))"
          >
            <i class="unlink icon"></i>
          </button>
        </div>
        <div class="ui icon buttons" style="margin-left: 10px">
          <button class="ui button" @click.prevent="commands.undo">
            <i class="undo icon"></i>
          </button>
          <button class="ui button" @click.prevent="commands.redo">
            <i class="redo icon"></i>
          </button>
        </div>
        <!-- Modal Link -->
        <ModalLink
          :editor.sync="editor"
          :isModalShowing.sync="isModalShowing"
          :isModalConfirmed.sync="isModalConfirmed"
          @modalConfirmed="setLinkUrl(commands.link, $event)"
          @modalCancelled="cancelModal"
          @saveLink="setLinkUrl(commands.link, $event)"
          @deleteLink="setLinkUrl(commands.link, null)"
        ></ModalLink>
      </div>
    </editor-menu-bar>
    <editor-content :editor="editor" class="editor" />
  </div>
</template>

<script>
import { EditorContent, EditorMenuBar } from "tiptap";
import ModalLink from "@/components/modals/ModalLink.vue";

export default {
  name: "FormEditor",
  props: ["editor"],
  components: {
    EditorContent,
    EditorMenuBar,
    ModalLink,
  },
  data() {
    return {
      isModalShowing: false,
      isModalConfirmed: false,
    };
  },
  methods: {
    showLinkMenu(attrs) {
      this.editor.linkUrl = attrs.href;
      this.editor.linkMenuIsActive = true;
      this.isModalShowing = true;
    },
    hideLinkMenu() {
      this.editor.linkUrl = null;
      this.editor.linkMenuIsActive = false;
      this.isModalShowing = false;
    },
    setLinkUrl(command, url) {
      command({ href: url });
      this.hideLinkMenu();
    },
    unsetLinkUrl(command, attrs) {
      this.editor.linkUrl = attrs.href;
      command({ href: null });
    },
    cancelModal() {
      this.isModalShowing = false;
    },
  },
};
</script>

<style>
.editor a:hover {
  cursor: pointer;
}
</style>
