<template>
  <div>
    <div v-if="previewImageSrc" style="margin-top: 8px" class="ui image">
      <img :src="previewImageSrc" class="image--preview" />
    </div>
    <image-uploader
      :preview="false"
      :quality="0.8"
      :maxWidth="800"
      :className="['fileinput', { 'fileinput--loaded': previewImageSrc }]"
      capture="environment"
      :debug="0"
      doNotResize="gif"
      :autoRotate="true"
      outputFormat="verbose"
      @input="setImage"
      @onUpload="loading = true"
      @onComplete="loading = false"
    >
      <label for="fileInput" slot="upload-label">
        <div
          class="ui placeholder segment"
          v-if="!previewImageSrc"
          style="min-height: 10rem"
        >
          <div class="ui button" :class="{ loading: loading }">
            Foto auswählen
          </div>
        </div>
        <div
          class="ui button labeled icon"
          v-if="previewImageSrc"
          @click="deleteImage"
        >
          <i class="trash icon"></i>
          Löschen
        </div>
        <div
          class="ui label floated green right"
          style="line-height: 1.5em; float: right"
          @click.prevent
          v-if="previewImageSrc && uploading"
        >
          <div
            class="ui active inline inverted loader mini"
            style="margin-top: -1px; margin-right: 10px"
          ></div>
          Foto wird hochgeladen…
        </div>
      </label>
    </image-uploader>
  </div>
</template>

<script>
export default {
  name: "ImageTitleSelect",
  props: ["sourceImage", "targetImage"],
  data() {
    return {
      hasImage: false,
      loading: false,
      uploading: false,
      image: null,
    };
  },
  computed: {
    previewImageSrc() {
      if (this.hasImage && this.image) {
        return this.image.dataUrl ? this.image.dataUrl : this.image;
      } else if (this.hasImage && this.image) {
        return this.image;
      } else if (
        this.hasImage &&
        this.targetImage &&
        this.targetImage.dataUrl
      ) {
        return this.targetImage.dataUrl;
      }
      return null;
    },
  },
  mounted() {
    if (this.sourceImage) {
      this.hasImage = true;
      this.image = this.sourceImage;
    }
  },
  methods: {
    setImage: function (output) {
      if (!output) {
        this.loading = false;
        this.hasImage = false;
        this.image = null;
        this.$emit("setImage", null);
      } else {
        this.loading = true;
        this.hasImage = true;
        this.image = output;
        this.loading = false;
        this.uploading = true;

        this.$emit("setImageUploading", true);
        this.$store
          .dispatch("image/upload", {
            fileName: output.info.name,
            fileDataUrl: output.dataUrl,
          })
          .then((filePath) => {
            this.$emit("setImage", filePath);
            this.uploading = false;
            this.$emit("setImageUploading", false);
          });
      }
    },
    deleteImage() {
      this.$emit("deleteImage");
      this.setImage(null);
    },
  },
};
</script>

<style>
.image--preview {
  min-width: 100%;
  max-width: 400px;
  max-height: 400px;
  margin-bottom: 8px;
  border-radius: 0.28571429rem;
}

#fileInput {
  display: none;
}
</style>
