<template>
  <modal modalVariation="small" v-model="isModalShowing">
    <div slot="header" class="center">Link festlegen</div>
    <div class="content" slot="content">
      <form class="ui form" @submit.prevent="saveLink()">
        <div class="ui field">
          <div class="field">
            <input
              type="text"
              v-model="editor.linkUrl"
              placeholder="https://"
              ref="linkInput"
              @keydown.esc="hideLinkMenu"
            />
            <p class="meta" style="margin-top: 4px">
              Füge einen gültigen Link ein und bestätige die Angabe per „Link
              speichern”.
            </p>
          </div>
        </div>
      </form>
    </div>
    <template slot="actions">
      <button
        class="ui red basic button"
        @click="deleteLink()"
        type="button"
        style="float: left"
      >
        <i class="trash icon" /> Link löschen
      </button>
      <div class="ui deny button" @click="cancelLeavingViewModal">
        <i class="remove icon"></i> Abbrechen
      </div>
      <div class="ui green right button" @click="confirmLeavingViewModal">
        <i class="checkmark icon"></i> Link speichern
      </div>
    </template>
  </modal>
</template>

<script>
import modal from "vue-semantic-modal";

export default {
  name: "ModalLink",
  props: ["isModalShowing", "isModalConfirmed", "editor"],
  components: {
    modal,
  },
  methods: {
    showLeavingViewModal() {
      this.$emit("update:isModalShowing", true);
      this.$emit("update:isModalConfirmed", false);
    },
    cancelLeavingViewModal() {
      this.$emit("update:isModalShowing", false);
      this.$emit("update:isModalConfirmed", false);
    },
    confirmLeavingViewModal() {
      this.cancelLeavingViewModal();
      this.$emit("cancelModalConfirmed");
      this.$emit("saveLink", this.editor.linkUrl);
    },
    deleteLink() {
      this.$emit("deleteLink");
    },
  },
};
</script>
