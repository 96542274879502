<template>
  <modal black v-model="isModalShowing" modalVariation="basic">
    <div slot="header" class="center">
      <i class="trash sign icon"></i>
      Löschen des Eintrags bestätigen
    </div>
    <div class="content" slot="content">
      <p>
        <strong>Möchten Sie diesen Eintrag wirklich unwiderruflich?</strong>
      </p>
      <p>
        Der Eintrag wird unwiderruflich gelöscht und kann später nicht
        wiederhergestellt werden.
      </p>
    </div>
    <template slot="actions">
      <div
        class="ui black deny inverted button"
        @click="cancelLeavingViewModal"
      >
        <i class="remove icon"></i> Abbrechen
      </div>
      <div
        class="ui red right inverted button"
        @click="confirmLeavingViewModal"
      >
        <i class="checkmark icon"></i> Ja, Eintrag löschen.
      </div>
    </template>
  </modal>
</template>

<script>
import modal from "vue-semantic-modal";

export default {
  name: "ModalDelete",
  props: ["isModalShowing", "isModalConfirmed"],
  components: {
    modal,
  },
  methods: {
    showLeavingViewModal() {
      this.$emit("update:isModalShowing", true);
      this.$emit("update:isModalConfirmed", false);
    },
    cancelLeavingViewModal() {
      this.$emit("update:isModalShowing", false);
      this.$emit("update:isModalConfirmed", false);
    },
    confirmLeavingViewModal() {
      this.cancelLeavingViewModal();
      this.$emit("modalConfirmed");
    },
  },
};
</script>
