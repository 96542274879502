<template>
  <modal v-model="isModalShowing" modalVariation="small">
    <div slot="header" class="center">
      <i class="warning sign icon"></i>
      Änderungen nicht gespeichert
    </div>
    <div class="content" slot="content">
      <p>
        <strong
          >Möchten Sie die Bearbeitung wirklich ohne Speichern
          verlassen?</strong
        >
      </p>
      <p>
        Die bisherigen Angaben wurden nicht gespeichert und können später nicht
        wiederhergestellt werden.
      </p>
    </div>
    <template slot="actions">
      <div class="ui deny button" @click="cancelLeavingViewModal">
        <i class="remove icon"></i> Nein
      </div>
      <div class="ui green right button" @click="confirmLeavingViewModal">
        <i class="checkmark icon"></i> Ja
      </div>
    </template>
  </modal>
</template>

<script>
import modal from "vue-semantic-modal";

export default {
  name: "ModalCancel",
  props: ["isModalShowing", "isModalConfirmed"],
  components: {
    modal,
  },
  methods: {
    showLeavingViewModal() {
      this.$emit("update:isModalShowing", true);
      this.$emit("update:isModalConfirmed", false);
    },
    cancelLeavingViewModal() {
      this.$emit("update:isModalShowing", false);
      this.$emit("update:isModalConfirmed", false);
    },
    confirmLeavingViewModal() {
      this.cancelLeavingViewModal();
      this.$emit("cancelModalConfirmed");
    },
  },
};
</script>
